import { mapActions, mapGetters, mapMutations } from "vuex"
import { format, downloadFile } from "@/utils"
import ThemisInput from "@/components/shared/input"
import ThemisInputAppend from "@/components/shared/input-append"
import ThemisInputAppendOuter from "@/components/shared/input-append-outer"
import ThemisDecision from "@/components/shared/decision"
import ThemisPrivacyPolicy from "@/components/channel/privacy-policy"
import ThemisTriage from "@/components/channel/triage"
import ThemisFile from "@/components/shared/file"
import QRCode from "qrcode"
import {
  ARRAY,
  ANCHOR_LINKS,
  MAX_CHARACTER_LIMIT,
  FILE_NAME,
  TRANSLATION_UPLOAD_STATUS,
  TRANSLATION_FILE_UPLOAD_TYPE,
  CHANNEL_DESCRIPTION_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL,
  TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES,
  CHANNEL_TRIAGE_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL,
  PRIVACY_POLICY_TEXT_TRANSLATION_STATUS_POLL_INTERVAL,
  REPORTER_PHONE_NUMBER_COPIED_TEXT_INTERVAL,
  ASK_ORGANISATION_CODE_FOR_WEB_ITEMS
} from "@/constants"
import { TRANSLATION_PREFERENCES } from "@/constants/locales"

export default {
  name      : "Channel",
  components: {
    ThemisInputAppend,
    ThemisInputAppendOuter,
    ThemisDecision,
    ThemisFile,
    ThemisInput,
    ThemisPrivacyPolicy,
    ThemisTriage
  },
  data: () => ({
    localChannel                                     : undefined,
    isNameInputFocussed                              : false,
    isSkipOrganisationCodeForWebInputFocussed        : false,
    isTranslationPreferenceInputFocussed             : false,
    isOrganisationCodeInputFocussed                  : false,
    isOverrideSkipOrganisationCodeForWebInputFocussed: false,
    isOverrideTranslationPreferenceInputFocussed     : false,
    isSecuritySettingsFocussed                       : false,
    isTranslationSettingFocussed                     : false,
    showDisableChannelDialog                         : false,
    isDisplayNameInputFocussed                       : false,
    isLinkDisplayNameInputFocussed                   : false,
    isLinkInputFocussed                              : false,
    displayDescriptionAlert                          : false,
    displayDescriptionSaveAndCancelButton            : false,
    channelDescriptionCharacterLimit                 : MAX_CHARACTER_LIMIT.CHANNEL_DESCRIPTION,
    translationConfigurationsForDisplay              : new Array,
    channelLogo                                      : undefined,
    isLogoRemovable                                  : false,
    allLanguagesEnabled                              : false,
    channelConfigurationTab                          : null,
    formTemplateToBeAdded                            : {},
    formTemplateToBeRemoved                          : null,
    isAddingTriage                                   : false,
    isRemovingTriage                                 : false,
    descriptionTranslationFileUploadStatusPolling    : null,
    triageTranslationFileUploadStatusPolling         : null,
    isPublishingNewPrivacyPolicy                     : false,
    privacyPolicyTextTranslationStatusPolling        : null,
    showReporterPhoneNumberUrlCopied                 : false,
    timerForCopiedText                               : null,
    askOrganisationCodeForWeb                        : 0,
    searchText                                       : null,
    translationPreferenceId                          : null,
    channelHelpCenterUrl                             : process.env.VUE_APP_CHANNEL_HELP_URL,
    receiveVoiceHelpCenterUrl                        : process.env.VUE_APP_CHANNEL_RECEIVE_VOICE_HELP_URL
  }),
  beforeDestroy() {
    clearInterval(this.descriptionTranslationFileUploadStatusPolling)
    clearInterval(this.triageTranslationFileUploadStatusPolling)
    clearInterval(this.privacyPolicyTextTranslationStatusPolling)
  },
  computed: {
    ...mapGetters({
      clientName                                  : "auth/clientName",
      channels                                    : "channels/channels",
      translationPreferences                      : "translationPreferences/translationPreferences",
      configurations                              : "configurations/configurations",
      availableOrganisationCodes                  : "organisationCodes/availableOrganisationCodes",
      domains                                     : "domains/domains",
      isUpdatingName                              : "channels/isUpdatingName",
      isNameUpdated                               : "channels/isNameUpdated",
      isUpdatingDisplayName                       : "channels/isUpdatingDisplayName",
      isDisplayNameUpdated                        : "channels/isDisplayNameUpdated",
      isUpdatingLink                              : "channels/isUpdatingLink",
      isLinkUpdated                               : "channels/isLinkUpdated",
      isUpdatingLinkDisplayName                   : "channels/isUpdatingLinkDisplayName",
      isLinkDisplayNameUpdated                    : "channels/isLinkDisplayNameUpdated",
      isUpdatingDescription                       : "channels/isUpdatingDescription",
      isDescriptionUpdated                        : "channels/isDescriptionUpdated",
      isUpdatingTranslationPreference             : "channels/isUpdatingTranslationPreference",
      isTranslationPreferenceUpdated              : "channels/isTranslationPreferenceUpdated",
      isUpdatingOrganisationCode                  : "channels/isUpdatingOrganisationCode",
      isOrganisationCodeUpdated                   : "channels/isOrganisationCodeUpdated",
      nameUpdateError                             : "channels/nameUpdateError",
      displayNameUpdateError                      : "channels/displayNameUpdateError",
      isEnabledUpdated                            : "channels/isEnabledUpdated",
      isUpdatingEnabled                           : "channels/isUpdatingEnabled",
      languages                                   : "languages/languages",
      translationConfigurations                   : "channels/translationConfigurations",
      isUpdatingTranslationConfigurations         : "channels/isUpdatingTranslationConfigurations",
      isTranslationConfigurationsUpdated          : "channels/isTranslationConfigurationsUpdated",
      isChannelLogoAdded                          : "channels/isChannelLogoAdded",
      isAddingChannelLogo                         : "channels/isAddingChannelLogo",
      isChannelLogoRemoved                        : "channels/isChannelLogoRemoved",
      formTemplates                               : "formTemplates/formTemplates",
      fieldsV2                                    : "fields/fieldsV2",
      formTemplateConfigurations                  : "formTemplateConfigurations/formTemplateConfigurations",
      formTemplatesOfChannels                     : "channels/formTemplatesOfChannels",
      isAddingChannelFormTemplates                : "channels/isAddingChannelFormTemplates",
      channelFormTemplatesAdded                   : "channels/channelFormTemplatesAdded",
      isRemovingChannelFormTemplate               : "channels/isRemovingChannelFormTemplate",
      isChannelFormTemplateRemoved                : "channels/isChannelFormTemplateRemoved",
      isConsentUpdated                            : "channels/isConsentUpdated",
      isUpdatingConsent                           : "channels/isUpdatingConsent",
      isReporterIntakeFormsEnabled                : "configurations/isReporterIntakeFormsEnabled",
      isDownloadingDescriptionTranslations        : "channels/isDownloadingDescriptionTranslations",
      isUploadingDescriptionTranslationFile       : "channels/isUploadingDescriptionTranslationFile",
      isUpdatingTriage                            : "channels/isUpdatingTriage",
      isTriageUpdated                             : "channels/isTriageUpdated",
      isTriageEnabled                             : "configurations/isTriageEnabled",
      isDownloadingTriageTranslations             : "channels/isDownloadingTriageTranslations",
      isUploadingTriageTranslationFile            : "channels/isUploadingTriageTranslationFile",
      isUpdatingPrivacyPolicy                     : "channels/isUpdatingPrivacyPolicy",
      isPrivacyPolicyUpdated                      : "channels/isPrivacyPolicyUpdated",
      isUpdatingDraftPrivacyPolicyText            : "channels/isUpdatingDraftPrivacyPolicyText",
      isDraftPrivacyPolicyTextUpdated             : "channels/isDraftPrivacyPolicyTextUpdated",
      draftPrivacyPolicyTranslations              : "channels/draftPrivacyPolicyTranslations",
      actualPrivacyPolicyTranslations             : "channels/privacyPolicyTranslations",
      isLoadingPrivacyPolicyTranslations          : "channels/isLoadingPrivacyPolicyTranslations",
      isUploadingDraftPrivacyPolicyTranslations   : "channels/isUploadingDraftPrivacyPolicyTranslations",
      isDraftPrivacyPolicyTranslationsFileUploaded: "channels/isDraftPrivacyPolicyTranslationsFileUploaded",
      isDisablingPrivacyPolicy                    : "channels/isDisablingPrivacyPolicy",
      isPrivacyPolicyDisabled                     : "channels/isPrivacyPolicyDisabled",
      isShowPhoneNumbersEnabled                   : "configurations/isShowPhoneNumbersEnabled",
      isClientAiSpeechToTextEnabled               : "configurations/isClientAiSpeechToTextEnabled"
    }),
    tabs() {
      return {
        [ANCHOR_LINKS.REPORT_INTAKE]                 : 0,
        [ANCHOR_LINKS.CUSTOMISATION]                 : 1,
        [ANCHOR_LINKS.LINK_DISPLAY_NAME_TRANSLATIONS]: 1,
        [ANCHOR_LINKS.PRIVACY_POLICY]                : this.isReporterIntakeFormsEnabled ? 2 : 1,
        [ANCHOR_LINKS.TRANSLATION_AND_LANGUAGE]      : this.isReporterIntakeFormsEnabled ? 3 : 2
      }
    },
    channel() {
      return this.channels.find(channel => channel.id === +this.$route.params.id)
    },
    domain() {
      return this.domains.find(domain => domain.id === this.channel.domainId)
    },
    translationPreferencesMap() {
      const translationPreferencesMap = new Object()
      for (const translationPreference of this.translationPreferences) {
        translationPreferencesMap[translationPreference.id] = translationPreference
      }
      return translationPreferencesMap
    },
    reporterUrl() {
      return format(process.env.VUE_APP_REPORTER_URL, this.clientName, this.localChannel.name)
    },
    reporterPhoneNumberUrl() {
      return format(process.env.VUE_APP_REPORTER_URL, this.clientName, `en-GB/${this.localChannel.name}/phone-numbers`)
    },
    isNameChanged() {
      return this.channel?.name !== this.localChannel?.name
    },
    isLinkChanged() {
      return this.channel?.link !== this.localChannel?.link
    },
    isLinkDisplayNameChanged() {
      return this.channel?.linkDisplayName !== this.localChannel?.linkDisplayName
    },
    isDescriptionChanged() {
      return this.channel?.description !== this.localChannel?.description
    },
    isConsentEdited() {
      return this.isConsentUpdated
    },
    isOrganisationCodeChanged() {
      return this.channel?.organisationCode !== this.localChannel?.organisationCode
    },
    isNameDuplicate() {
      return this.nameUpdateError?.type === "duplicate"
    },
    isDisplayNameDuplicate() {
      return this.displayNameUpdateError?.type === "duplicate"
    },
    isDisplayNameChanged() {
      return this.channel?.displayName !== this.localChannel?.displayName
    },
    effectiveSkipOrganisationCodeForWeb() {
      return this.localChannel.overrideAskOrganisationCodeForWeb ?
        this.localChannel.askOrganisationCodeForWeb : this.clientConfiguration.askOrganisationCodeForWeb
    },
    organisationCodesForSelection() {
      const organisationCodes = []
      if (this.localChannel.organisationCode) {
        organisationCodes.push(this.localChannel.organisationCode)
      }
      if (this.availableOrganisationCodes) {
        organisationCodes.push(...this.availableOrganisationCodes.map(organisationCode => organisationCode.id))
      }
      return organisationCodes
    },
    clientConfiguration() {
      const clientConfiguration = {}
      if (this.configurations) {
        for (const configuration of this.configurations) {
          if (configuration.key === this.$CONSTANTS.CONFIGURATIONS.ASK_ORGANISATION_CODE_FOR_WEB) {
            clientConfiguration.askOrganisationCodeForWeb = (configuration.value === "true")
          } else if (configuration.key === this.$CONSTANTS.CONFIGURATIONS.TRANSLATION) {
            clientConfiguration.translationPreferenceId = configuration.value
          }
        }
      }
      return clientConfiguration
    },
    effectiveChannelTranslationPreferenceId() {
      return this.channel?.overrideTranslationPreference ?
        this.channel.translationPreferenceId : +this.clientConfiguration.translationPreferenceId
    },
    effectiveChannelAskOrganisationCodeForWeb() {
      return this.channel?.overrideAskOrganisationCodeForWeb ?
        this.channel.askOrganisationCodeForWeb :
        this.clientConfiguration.askOrganisationCodeForWeb
    },
    disableVoiceMessage() {
      const isTranslationMachineOnly = [this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_ONLY].includes(
        this.effectiveChannelTranslationPreferenceId)
      const isNoTranslationChannel   = [this.$CONSTANTS.TRANSLATION_PREFERENCES.NO_TRANSLATION].includes(
        this.effectiveChannelTranslationPreferenceId)

      if ((isTranslationMachineOnly && !this.isClientAiSpeechToTextEnabled)
        || (!this.isShowPhoneNumbersEnabled && !this.isClientAiSpeechToTextEnabled)
        || isNoTranslationChannel) {
        return true
      } else {
        return false
      }
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    translationConfigurationsOfChannel() {
      return this.translationConfigurations[+this.$route.params.id]
    },
    canComputeTranslationConfigurationsForDisplay() {
      return !!(
        Object.keys(this.languagesMap).length &&
        this.translationConfigurationsOfChannel?.length &&
        this.effectiveChannelTranslationPreferenceId
      )
    },
    headersForTranslationConfigurationsTable() {
      return this.$TABLES.CHANNEL_TRANSLATION_CONFIGURATIONS.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    translationConfigurationsForUpdating() {
      const translationConfigurationsForUpdating = new Array()
      for (const translationConfigurationForDisplay of this.translationConfigurationsForDisplay) {
        if (translationConfigurationForDisplay.enabled) {
          translationConfigurationsForUpdating.push({
            languageId                : translationConfigurationForDisplay.languageId,
            enableTranslationToEnglish: translationConfigurationForDisplay.enableTranslationToEnglish
          })
        }
      }
      return translationConfigurationsForUpdating
    },
    channelFormTemplate() {
      let channelFormTemplate
      const channelFormTemplateId = this.formTemplatesOfChannels[this.channel.id]?.[ARRAY.FIRST]
      if (channelFormTemplateId) {
        channelFormTemplate = this.formTemplates.find(formTemplate => formTemplate.id === channelFormTemplateId)
      }
      return channelFormTemplate
    },
    channelFormTemplateName() {
      return this.channelFormTemplate?.name
    },
    channelFormTemplateFieldsForDisplay() {
      let channelFormTemplateFields

      if (this.channelFormTemplate) {
        const fieldNames          = this.getFormTemplateFieldNames(this.channelFormTemplate.id)
        channelFormTemplateFields = fieldNames?.length ? fieldNames.join(", ") : this.$t("741")
      }
      return channelFormTemplateFields
    },
    fieldsMap() {
      const fieldsMap = new Map()
      for (const field of this.fieldsV2) {
        fieldsMap.set(field.id, field)
      }
      return fieldsMap
    },
    formTemplateFieldsMap() {
      const formTemplateFieldsMap = new Map()
      for (const configuration of this.formTemplateConfigurations) {
        let fields = [this.fieldsMap.get(configuration.fieldId)]
        if (formTemplateFieldsMap.has(configuration.formTemplateId)) {
          fields = [...fields, ...formTemplateFieldsMap.get(configuration.formTemplateId)]
        }
        formTemplateFieldsMap.set(configuration.formTemplateId, fields)
      }
      return formTemplateFieldsMap
    },
    formTemplateItems() {
      return this.formTemplates?.map(formTemplate => {
        const fieldNames = this.getFormTemplateFieldNames(formTemplate.id)
        return {
          id    : formTemplate.id,
          name  : formTemplate.name,
          fields: fieldNames?.length ? fieldNames.join(", ") : this.$t("741")
        }
      })
    },
    allowedTranslationDocumentType() {
      return Object.values(TRANSLATION_FILE_UPLOAD_TYPE).toString()
    },
    descriptionTranslationUploadStatus() {
      return this.channel?.descriptionTranslationUploadStatus
    },
    isDescriptionTranslationUploadInitiated() {
      return this.descriptionTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.INITIATED
    },
    isDescriptionTranslationUploadFailed() {
      return this.descriptionTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.FAILURE
    },
    triageTranslationUploadStatus() {
      return this.channel?.triageTranslationUploadStatus
    },
    hasChannelDescription() {
      return this.channel.description &&  this.channel.description !== ""
    },
    privacyPolicyTranslations() {
      if (this.draftPrivacyPolicyTranslations && Object.keys(this.draftPrivacyPolicyTranslations).length) {
        return this.draftPrivacyPolicyTranslations
      } else if (this.actualPrivacyPolicyTranslations && Object.keys(this.actualPrivacyPolicyTranslations).length) {
        return this.actualPrivacyPolicyTranslations
      }
    },
    isChannelSettingNoTranslation() {
      return this.effectiveChannelTranslationPreferenceId === this.$CONSTANTS.TRANSLATION_PREFERENCES.NO_TRANSLATION
    },
    askOrganisationCodeForWebItems() {
      const askOrganisationCodeForWebItems = Object.values(ASK_ORGANISATION_CODE_FOR_WEB_ITEMS).map(item => ({
        value: item.value,
        text : this.$t(item.text)
      }))
      askOrganisationCodeForWebItems.unshift({
        value: 0,
        text : this.$t("454", { name: this.$t(ASK_ORGANISATION_CODE_FOR_WEB_ITEMS[this.clientConfiguration.askOrganisationCodeForWeb.toString()].text) })
      })
      return askOrganisationCodeForWebItems
    },
    translationPreferencesToDisplay() {
      const translationPreferencesToDisplay = []
      for (const translationPreference of this.translationPreferences) {
        translationPreferencesToDisplay.push({
          id  : translationPreference.id,
          name: this.$t(TRANSLATION_PREFERENCES[translationPreference.name])
        })
      }
      translationPreferencesToDisplay.sort((translationPreferenceFirst, translationPreferenceSecond) =>
        translationPreferenceFirst.name.localeCompare(translationPreferenceSecond.name))
      translationPreferencesToDisplay.unshift({
        id  : 0,
        name: this.$t("390", { name: this.$t(TRANSLATION_PREFERENCES[this.translationPreferencesMap[this.clientConfiguration.translationPreferenceId].name]) })
      })
      return translationPreferencesToDisplay
    },
    hasChannelLinkDisplayName() {
      return !!this.channel.linkDisplayName
    }
  },
  methods: {
    ...mapActions({
      updateChannel                          : "channels/updateChannel",
      updateTranslationConfigurations        : "channels/updateTranslationConfigurations",
      notify                                 : "shared/notify",
      addChannelLogo                         : "channels/addChannelLogo",
      removeChannelLogo                      : "channels/removeChannelLogo",
      addChannelFormTemplates                : "channels/addChannelFormTemplates",
      removeChannelFormTemplate              : "channels/removeChannelFormTemplate",
      downloadDescriptionTranslations        : "channels/downloadDescriptionTranslations",
      downloadTriageTranslations             : "channels/downloadTriageTranslations",
      uploadDescriptionTranslationFile       : "channels/uploadDescriptionTranslationFile",
      loadChannels                           : "channels/loadChannels",
      loadChannel                            : "channels/loadChannel",
      uploadTriageTranslationFile            : "channels/uploadTriageTranslationFile",
      loadPrivacyPolicyTranslations          : "channels/loadPrivacyPolicyTranslations",
      uploadDraftPrivacyPolicyTranslationFile: "channels/uploadDraftPrivacyPolicyTranslationFile"
    }),
    ...mapMutations({
      resetChannelUpdateError: "channels/resetChannelUpdateError"
    }),
    handleChangeBackToGlobalSettingForOrganisationCode() {
      this.localChannel.overrideAskOrganisationCodeForWeb = false
      this.updateChannel({
        overrideAskOrganisationCodeForWeb: false,
        id                               : this.localChannel.id
      })
    },
    handleAskOrganisationCodeForWebItems() {
      if (this.askOrganisationCodeForWeb === 0) {
        this.handleChangeBackToGlobalSettingForOrganisationCode()
      } else if (this.askOrganisationCodeForWeb !== this.channel.askOrganisationCodeForWeb ||
         !this.localChannel.overrideAskOrganisationCodeForWeb ) {
        const channelSettingToUpdate                = {
          id                       : this.localChannel.id,
          askOrganisationCodeForWeb: this.askOrganisationCodeForWeb
        }
        this.localChannel.askOrganisationCodeForWeb = this.askOrganisationCodeForWeb
        if (!this.localChannel.overrideAskOrganisationCodeForWeb) {
          this.localChannel.overrideAskOrganisationCodeForWeb      = true
          channelSettingToUpdate.overrideAskOrganisationCodeForWeb = true
        }
        this.updateChannel(channelSettingToUpdate)
      }
    },
    handleChangeBackToGlobalSettingForTranslation() {
      this.localChannel.overrideTranslationPreference = false
      this.updateChannel({
        overrideTranslationPreference: false,
        id                           : this.localChannel.id
      })
    },
    handleTranslationPreferenceSetting() {
      if (this.translationPreferenceId === 0) {
        this.handleChangeBackToGlobalSettingForTranslation()
      } else if (this.translationPreferenceId !== this.channel.translationPreferenceId ||
        !this.localChannel.overrideTranslationPreference) {
        const channelSettingToUpdate              = {
          id                     : this.localChannel.id,
          translationPreferenceId: this.translationPreferenceId
        }
        this.localChannel.translationPreferenceId = this.translationPreferenceId
        if (!this.localChannel.overrideTranslationPreference) {
          this.localChannel.overrideTranslationPreference      = true
          channelSettingToUpdate.overrideTranslationPreference = true
        }
        this.updateChannel(channelSettingToUpdate)
      }
    },
    downloadChannelDescriptionTranslations() {
      this.downloadDescriptionTranslations({
        id  : this.localChannel.id,
        name: FILE_NAME.CHANNEL_DESCRIPTION_TRANSLATIONS(this.localChannel.name)
      })
    },
    handleDownloadTriageTranslations() {
      this.downloadTriageTranslations({
        id  : this.localChannel.id,
        name: FILE_NAME.CHANNEL_TRIAGE_TRANSLATIONS(this.localChannel.name)
      })
    },
    updateChannelLinkData() {
      if (this.isLinkChanged) {
        if (!this.localChannel.link) {
          this.localChannel.link = ""
        }
        this.updateChannel({
          id  : this.localChannel.id,
          link: this.localChannel.link
        })
      } else if (this.isLinkDisplayNameChanged) {
        if (!this.localChannel.linkDisplayName) {
          this.localChannel.linkDisplayName = ""
        }
        this.updateChannel({
          id             : this.localChannel.id,
          linkDisplayName: this.localChannel.linkDisplayName
        })
      }
    },
    handleInputLinkOnBlur(onBlur) {
      onBlur()
      this.updateChannelLinkData()
    },
    handleInputLinkInputOnEnter() {
      this.$refs.input_link.blur()
    },
    handleInputLinkDisplayNameOnFocus(onFocus) {
      onFocus()
      this.isLinkDisplayNameInputFocussed = true
    },
    handleInputLinkDisplayNameOnEnter() {
      this.$refs.input_link_display_name.blur()
    },
    handleInputLinkDisplayNameOnBlur(onBlur) {
      onBlur()
      this.isLinkDisplayNameInputFocussed = false
      this.updateChannelLinkData()
    },
    handleDescriptionOnFocusEvent(onFocus) {
      onFocus()
      this.displayDescriptionAlert               = true
      this.displayDescriptionSaveAndCancelButton = true
    },
    handleCancelDescription() {
      this.localChannel.description              = this.channel.description
      this.displayDescriptionSaveAndCancelButton = false
      this.displayDescriptionAlert               = false
    },
    handleUpdateDescription() {
      this.updateChannel({
        id         : this.localChannel.id,
        description: this.localChannel.description ?? ""
      })
    },
    async qrCodeDownload() {
      const qrCodeUrl = await QRCode.toDataURL([{
        data: `${this.reporterUrl}?organisation-code=${this.channel.organisationCode}`
      }])
      downloadFile(qrCodeUrl, undefined, `${this.localChannel.displayName}.png`)
    },
    computeTranslationConfigurationsForDisplay() {
      const translationConfigurationsForDisplay = new Array()
      const languagesCovered                    = new Array()
      const isSelectable                        = language => {
        if (language.shortName === this.$CONSTANTS.LANGUAGE.CLIENT_DEFAULT.shortName) {
          return false
        }
        if (this.effectiveChannelTranslationPreferenceId === this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_ONLY) {
          if (language.machineTranslatable) {
            return true
          }
          return false
        }
        return true
      }
      // TODO select all machine translatable language is pending.
      for (const translationConfigurationOfChannel of this.translationConfigurationsOfChannel) {
        const language                           = this.languagesMap[translationConfigurationOfChannel.languageId]
        const translationConfigurationForDisplay = {
          languageId                : language.id,
          languageName              : `${language.name} - ${language.localName}`,
          enabled                   : true,
          enableTranslationToEnglish: translationConfigurationOfChannel.enableTranslationToEnglish,
          humanTranslatable         : language.humanTranslatable,
          machineTranslatable       : language.machineTranslatable,
          selectable                : isSelectable(language),
          human                     : language.humanTranslatable,
          machineText               : language.machineTranslatable,
          machineSpeech             : language.machineTranscribable
        }
        languagesCovered.push(language.id)
        translationConfigurationsForDisplay.push(translationConfigurationForDisplay)
      }
      for (const language of this.languages) {
        if (!languagesCovered.includes(language.id)) {
          translationConfigurationsForDisplay.push({
            languageId                : language.id,
            languageName              : `${language.name} - ${language.localName}`,
            enabled                   : false,
            enableTranslationToEnglish: !this.isChannelSettingNoTranslation,
            humanTranslatable         : language.humanTranslatable,
            machineTranslatable       : language.machineTranslatable,
            selectable                : isSelectable(language),
            human                     : language.humanTranslatable,
            machineText               : language.machineTranslatable,
            machineSpeech             : language.machineTranscribable
          })
        }
      }
      this.translationConfigurationsForDisplay =
        translationConfigurationsForDisplay.sort((language1, language2) => language1.languageId - language2.languageId)
    },
    openFileExplorer() {
      this.$refs.input_logo.click()
    },
    onLogoSelect(event) {
      this.channelLogo = event.target.files[0]
      if (this.channelLogo) {
        this.localChannel.logo = {
          name: this.channelLogo.name,
          size: this.channelLogo.size
        }
        this.addChannelLogo({
          id  : this.localChannel.id,
          file: this.channelLogo
        })
      }
    },
    getFormTemplateFieldNames(formTemplateId) {
      return this.formTemplateFieldsMap
        ?.get(formTemplateId)
        ?.map(field => field?.systemName)
    },
    handleReporterIntakeFormChange(onBlur) {
      onBlur()
      if (this.formTemplateToBeAdded?.id && this.formTemplateToBeAdded.id !== this.channelFormTemplate?.id) {
        const payload = {
          id           : this.channel.id,
          formTemplates: [this.formTemplateToBeAdded.id]
        }
        this.addChannelFormTemplates(payload)
      }
    },
    handleReporterIntakeFormDelete() {
      if (this.channelFormTemplate?.id) {
        this.formTemplateToBeRemoved = this.channelFormTemplateName
        const deletePayload          = {
          channelId     : this.channel.id,
          formTemplateId: this.channelFormTemplate.id
        }
        this.removeChannelFormTemplate(deletePayload)
      }
    },
    handleTabClickEvent(hash) {
      this.channelConfigurationTab = this.tabs[hash] || this.tabs[ANCHOR_LINKS.REPORT_INTAKE]
      if (this.$route.hash !== hash) {
        this.$router.push({ hash })
      }
    },
    handleUpdateConsent(consent) {
      consent.id = this.localChannel.id
      this.updateChannel(consent)
    },
    handleUpdatePrivacyPolicy(privacyPolicy) {
      privacyPolicy.id = this.localChannel.id
      this.updateChannel(privacyPolicy)
    },
    handleAddTriage(triageValues) {
      this.isAddingTriage = true
      this.updateChannel(triageValues)
    },
    handleRemoveTriage(triageValues) {
      this.isRemovingTriage = true
      this.updateChannel(triageValues)
    },
    openFileExplorerToSelectDocument() {
      this.$refs.input_upload_description_translation_document.click()
    },
    onDocumentSelection(event) {
      const file = event.target.files[ARRAY.FIRST]
      if( file.size < TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES) {
        this.uploadDescriptionTranslationFile({ channel: this.channel, file: file })
      }
    },
    handleAddTriageDocument(file) {
      this.uploadTriageTranslationFile({
        channel: this.localChannel,
        file
      })
    },
    clearDescriptionTranslationFileUploadStatusPollingInterval() {
      if(this.descriptionTranslationFileUploadStatusPolling) {
        clearInterval(this.descriptionTranslationFileUploadStatusPolling)
        this.descriptionTranslationFileUploadStatusPolling = null
      }
    },
    clearTriageTranslationFileUploadStatusPollingInterval() {
      if(this.triageTranslationFileUploadStatusPolling) {
        clearInterval(this.triageTranslationFileUploadStatusPolling)
        this.triageTranslationFileUploadStatusPolling = null
      }
    },
    handleUpdateTriage(triageValues) {
      this.isAddingTriage = triageValues.isAddingTriage
      triageValues.id     = this.localChannel.id
      delete triageValues.isAddingTriage
      this.updateChannel(triageValues)
    },
    handleUpdatePrivacyPolicyTranslations(draftPrivacyPolicyTranslations) {
      // convert this privacyPolicyTranslations to an json file
      this.uploadDraftPrivacyPolicyTranslationFile({
        id: this.localChannel.id,
        draftPrivacyPolicyTranslations
      })
    },
    async handleCopyReporterPhoneNumberUrl() {
      await navigator.clipboard.writeText(this.reporterPhoneNumberUrl)
      this.showReporterPhoneNumberUrlCopied = true
      if (this.timerForCopiedText) {
        clearTimeout(this.timerForCopiedText)
      }
      this.timerForCopiedText = setTimeout(() =>
        this.showReporterPhoneNumberUrlCopied = false, REPORTER_PHONE_NUMBER_COPIED_TEXT_INTERVAL)
    },
    getHintForOrganisationCodeWebItems() {
      if (this.localChannel.overrideAskOrganisationCodeForWeb) {
        return this.$t("216")
      }
    },
    handleOpeningSidePanel() {
      if (this.$route.hash !== ANCHOR_LINKS.LINK_DISPLAY_NAME_TRANSLATIONS) {
        this.$router.push({ hash: ANCHOR_LINKS.LINK_DISPLAY_NAME_TRANSLATIONS })
      }
    },
    getHintForTranslationPreferences() {
      if (this.localChannel.overrideTranslationPreference) {
        return this.$t("2427")
      }
    }
  },
  watch: {
    channel: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localChannel) {
          this.localChannel = { ...newValue }

        }
      }
    },
    channelFormTemplate: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.formTemplateToBeAdded = newValue
        }
      }
    },
    "localChannel.name": {
      immediate: true,
      handler  : function() {
        if (this.nameUpdateError) {
          this.resetChannelUpdateError(["name"])
        }
      }
    },
    "localChannel.displayName": {
      immediate: true,
      handler  : function() {
        if (this.displayNameUpdateError) {
          this.resetChannelUpdateError(["displayName"])
        }
      }
    },
    "channel.privacyPolicyTextTranslationStatus": {
      handler: function(newValue) {
        if (newValue) {
          this.privacyPolicyTextTranslationStatusPolling = setInterval(() => {
            this.loadChannel(this.channel.id)
          }, PRIVACY_POLICY_TEXT_TRANSLATION_STATUS_POLL_INTERVAL)
        } else {
          if (this.privacyPolicyTextTranslationStatusPolling) {
            this.loadPrivacyPolicyTranslations({
              id    : this.channel.id,
              draft : true,
              actual: true
            })

            clearInterval(this.privacyPolicyTextTranslationStatusPolling)
            this.privacyPolicyTextTranslationStatusPolling = null
          }
        }
      }
    },
    canComputeTranslationConfigurationsForDisplay: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.computeTranslationConfigurationsForDisplay()
        }
      }
    },
    isTranslationConfigurationsUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.notify({
            type: "primary",
            text: "445"
          })
        }
      }
    },
    isEnabledUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.showDisableChannelDialog = false
          this.localChannel.enabled     = false
          this.notify({
            type      : "success",
            text      : "434",
            parameters: {
              id: this.localChannel.id
            }
          })
        }
      }
    },
    isUpdatingEnabled: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.CHANNEL_DISABLE.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.CHANNEL_DISABLE.pActions[0].buttonProps.disabled = newValue
      }
    },
    isAddingChannelLogo: {
      immediate: true,
      handler  : function(newValue) {
        this.isLogoRemovable = !newValue
      }
    },
    isChannelLogoRemoved: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.localChannel.logo = null
        }
      }
    },
    translationConfigurationsForDisplay: {
      immediate: false,
      deep     : true,
      handler  : function(newValue) {
        if (newValue) {
          for (const translationConfiguration of this.translationConfigurationsForDisplay) {
            if (this.effectiveChannelTranslationPreferenceId === this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_ONLY) {
              if (translationConfiguration.machineTranslatable && !translationConfiguration.enabled) {
                this.allLanguagesEnabled = false
                return
              }
            } else if (!translationConfiguration.enabled) {
              this.allLanguagesEnabled = false
              return
            }
          }
          this.allLanguagesEnabled = true
        }
      }
    },
    effectiveChannelTranslationPreferenceId: {
      immediate: false,
      handler  : function(newValue, oldValue) {
        if ((newValue === this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_ONLY ||
          oldValue === this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_ONLY)
          && this.canComputeTranslationConfigurationsForDisplay) {
          this.computeTranslationConfigurationsForDisplay()
        }
      }
    },
    allLanguagesEnabled: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          for (const translationConfiguration of this.translationConfigurationsForDisplay) {
            if (this.effectiveChannelTranslationPreferenceId === this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_ONLY) {
              if (translationConfiguration.machineTranslatable) {
                translationConfiguration.enabled = true
              } else {
                translationConfiguration.enabled = false
              }
            } else {
              translationConfiguration.enabled = true
            }
          }
        } else {
          const allTranslationItemsSelected = this.translationConfigurationsForDisplay.every(
            translationConfiguration => translationConfiguration.enabled
          )

          const isMachineOnlyTranslationAndAllMachineTranslationItemsSelected = this.translationConfigurationsForDisplay
            .filter(translationConfiguration => translationConfiguration.machineTranslatable)
            .every(translationConfiguration => translationConfiguration.enabled)
            && this.effectiveChannelTranslationPreferenceId === this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_ONLY

          if (allTranslationItemsSelected || isMachineOnlyTranslationAndAllMachineTranslationItemsSelected) {
            for (const translationConfiguration of this.translationConfigurationsForDisplay) {
              if (translationConfiguration.languageId === this.$CONSTANTS.LANGUAGE.CLIENT_DEFAULT.id) {
                translationConfiguration.enabled = true
              } else {
                translationConfiguration.enabled = false
              }
            }
          }
        }
      }
    },
    translationConfigurationsOfChannel: {
      immediate: false,
      deep     : true,
      handler  : function(newValue) {
        if (newValue && this.canComputeTranslationConfigurationsForDisplay) {
          this.computeTranslationConfigurationsForDisplay()
        }
      }
    },
    channelFormTemplatesAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "832",
            parameters: {
              formTemplateName: this.channelFormTemplateName
            }
          })
        }
      }
    },
    isChannelFormTemplateRemoved: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "840",
            parameters: {
              formTemplateName: this.formTemplateToBeRemoved
            }
          })
          this.formTemplateToBeRemoved = null
        }
      }
    },
    isDescriptionUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.displayDescriptionAlert               = false
          this.displayDescriptionSaveAndCancelButton = false
          this.notify({
            type: "success",
            text: "1646"
          })
        }
      }
    },
    "$route.hash": {
      immediate: true,
      handler  : function(hash) {
        if(hash){
          this.handleTabClickEvent(hash)
        }
      }
    },
    isConsentEdited: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "1338"
          })
        }
      }
    },
    descriptionTranslationUploadStatus: {
      immediate: true,
      handler  : function(newValue, oldValue) {
        if(newValue === TRANSLATION_UPLOAD_STATUS.INITIATED) {
          this.descriptionTranslationFileUploadStatusPolling = setInterval(() => {
            this.loadChannels({ id: this.channel.id, uploadStatus: newValue })
          }, CHANNEL_DESCRIPTION_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL)
        }
        if(newValue === TRANSLATION_UPLOAD_STATUS.SUCCESS || newValue === TRANSLATION_UPLOAD_STATUS.FAILURE) {
          this.clearDescriptionTranslationFileUploadStatusPollingInterval()
        }
        if(newValue === TRANSLATION_UPLOAD_STATUS.SUCCESS && oldValue === TRANSLATION_UPLOAD_STATUS.INITIATED) {
          this.notify({
            type: "success",
            text: "1059"
          })
        }
      }
    },
    triageTranslationUploadStatus: {
      immediate: true,
      handler  : function(newValue, oldValue) {
        if (newValue === TRANSLATION_UPLOAD_STATUS.INITIATED) {
          this.triageTranslationFileUploadStatusPolling = setInterval(() => {
            this.loadChannels({ id: this.channel.id, uploadStatus: newValue })
          }, CHANNEL_TRIAGE_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL)
        }
        if (newValue === TRANSLATION_UPLOAD_STATUS.SUCCESS || newValue === TRANSLATION_UPLOAD_STATUS.FAILURE) {
          this.clearTriageTranslationFileUploadStatusPollingInterval()
        }
        if (newValue === TRANSLATION_UPLOAD_STATUS.SUCCESS && oldValue === TRANSLATION_UPLOAD_STATUS.INITIATED) {
          this.notify({
            type: "success",
            text: "1712"
          })
        }
      }
    },
    isTriageUpdated: {
      handler: function(value) {
        if (value) {
          if (this.isAddingTriage) {
            this.notify({
              type: "success",
              text: "1613"
            })
          } else if (this.isRemovingTriage) {
            this.notify({
              type: "success",
              text: "1709"
            })
          } else {
            this.notify({
              type: "success",
              text: "1702"
            })
          }
          this.isAddingTriage   = false
          this.isRemovingTriage = false
        }
      }
    },
    isPrivacyPolicyUpdated: {
      handler: function(value) {
        if (value) {
          if (this.isPublishingNewPrivacyPolicy) {
            this.notify({
              type: "success",
              text: "1757"
            })
          } else {
            this.notify({
              type: "success",
              text: "1728"
            })
          }
        }
      }
    },
    isDraftPrivacyPolicyTextUpdated: {
      handler: function(value) {
        if (value && this.channel.privacyPolicyTextTranslationStatus) {
          this.notify({
            type: "success",
            text: "1748"
          })
        }
      }
    },
    isPrivacyPolicyDisabled: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "1767"
          })
        }
      }
    },
    isDraftPrivacyPolicyTranslationsFileUploaded: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "1777"
          })
        }
      }
    },
    "localChannel.overrideAskOrganisationCodeForWeb": {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.askOrganisationCodeForWeb = this.localChannel.askOrganisationCodeForWeb
        } else {
          this.askOrganisationCodeForWeb = 0
        }
      }
    },
    "localChannel.overrideTranslationPreference": {
      immediate: true,
      handler  : function(value) {
        if (value) {
          this.translationPreferenceId = this.localChannel.translationPreferenceId
        } else {
          this.translationPreferenceId = 0 // in order for v-select to display the global setting value
        }
        if (this.effectiveChannelTranslationPreferenceId === this.$CONSTANTS.TRANSLATION_PREFERENCES.NO_TRANSLATION
          && this.channel.enableVoiceMessage === this.$CONSTANTS.BOOLEAN.TRUE
        ) {
          this.updateChannel({
            id                : this.channel.id,
            enableVoiceMessage: false,
            receiveVoiceFile  : false
          })
          this.localChannel.enableVoiceMessage = false
          this.localChannel.receiveVoiceFile   = false
        }
      }
    },
    isLinkDisplayNameUpdated: {
      handler: function(newValue) {
        if (newValue && !this.channel.linkDisplayName &&
          this.$route.hash === ANCHOR_LINKS.LINK_DISPLAY_NAME_TRANSLATIONS ) {
          this.$router.push({ hash: ANCHOR_LINKS.CUSTOMISATION })
        }
      }
    }
  }
}